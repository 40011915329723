<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2"
          >2021 &nbsp;&copy;&nbsp;</span
        >
        <a class="text-dark-75 text-hover-primary">Airtrading&nbsp;</a>
        <a class="text-dark-75 text-hover-primary"> {{ version }}</a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex';
import { VERSION_APP } from '../../../utils/constants';

export default {
  name: 'KTFooter',
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    },
  },
  data() {
    return {
      version: VERSION_APP,
    };
  },
};
</script>
