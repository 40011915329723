<template>
  <div class="topbar-item">
    <div
      class="
        btn btn-icon
        w-auto
        d-flex
        align-items-center
        btn-lg
        px-2
      "     
      id="kt_quick_user_toggle"
    >
      <span
        v-if="!isTabletSize"
        class="
          font-weight-bold font-size-base
          d-none d-md-inline
        "
        :class="[isNewLayout ? 'text-white' : 'text-dark-50']"
        >Hi,</span
      >
      <span
        v-if="!isTabletSize"
        class="
          font-weight-bolder font-size-base
          d-none d-md-inline
        "
        :class="[isNewLayout ? 'text-white' : 'text-dark-50']"
      >
        {{ employee.fullName }}
        </span
      >
      <span class="symbol symbol-35 symbol-light-success">
        <span v-if="isTabletSize" class="w-auto px-3 symbol-label font-size-h5 font-weight-bold">
          {{ employee.fullName }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3>Thông tin cá nhân</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img src="media/users/default.jpg" alt="" class="symbol-label" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href=""
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
                router-link-exact-active router-link-active
              "
            >
              {{ employee.fullName }}
            </a>
            <div class="text-muted mt-2">
              <i class="fas fa-solid fa-hashtag"></i>
              {{ employee.id }}
            </div>
            <div class="text-muted mt-2">
              <i class="fas fa-mobile-alt" style="margin-right: 5px"></i
              >{{ employee.mobile }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-text text-muted text-hover-primary">
                    <i class="fas fa-at"></i> {{ employee.email }}
                  </span></span
                ></a
              >
            </div>
            <!--begin: User Bar -->
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Đăng xuất
            </button>
            <!--end: User Bar -->
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/change-password" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Đổi mật khẩu</div>
                <div class="text-muted">
                  Đổi mật khẩu đăng nhập
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/change-store" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Chuyển cửa hàng</div>
                <div class="text-muted">
                  Chuyển cửa hàng của bạn
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/builder" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Cấu hình giao diện</div>
                <div class="text-muted">
                  Chuyển đổi bố cục app
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="separator separator-dashed my-7"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from '@/core/services/store/modules/auth.module';
import { RESET_MENU_WHEN_LOGOUT } from '@/core/services/store/modules/menu.module';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import { getData } from '@/utils/saveDataToLocal';
import KTUtil from '@/assets/js/components/util';

export default {
  name: 'KTQuickUser',
  data() {
    return {
      employee: {},
      isTabletSize: KTUtil.isBreakpointDown('lg'),
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'Airtrading (AT)' },
        { value: 2, text: 'TheGioiMayCu (TGMC)' },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkTabletSize);
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    this.employee = this.getEmployee();
    this.$nextTick(() => {
      window.addEventListener('resize', this.checkTabletSize);
    })
  },
  methods: {
    checkTabletSize() {
      this.isTabletSize =
        KTUtil.isBreakpointDown('lg');
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }));
      this.$store.dispatch(RESET_MENU_WHEN_LOGOUT);
    },
    getEmployee() {
      const employeeInfo = getData('employee_info');
      if (employeeInfo) {
        const tmpInfo = JSON.parse(employeeInfo);
        return tmpInfo;
      }
      return { fullName: 'Bạn' };
    },
  },
  computed: { 
    isNewLayout(){
      if (getData('config')) return true 
      return false;
    },
  }
};
</script>
